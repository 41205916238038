import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-competition-stats',
    templateUrl: './competition-stats.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetCompetitionStatsComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
        competitionId: string | number | null | undefined;

    @Input()
        seasonId: string | number | null | undefined;

    #headlineTrans = 'web.opta_competition_stats';
    private transHeadline = '';


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
        public translate: TranslateService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.transHeadline = this.translate.instant(this.#headlineTrans);
        setTimeout(() => {
            const element = document.querySelector('#opta-competition-stats') as HTMLElement;

            if (element) {
                element.innerHTML = this.getHtml();
                const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                if (optaWidgetTags) {
                    optaWidgetTags.removeAttribute('load');
                    Opta.start();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (Opta.widgets.ref_widget_competition_stats) {
            Opta.widgets.ref_widget_competition_stats.destroy(true);
        }
    }

    getHtml(): string {
        return `

          <opta-widget widget="competition_stats"  widget_id="ref_widget_competition_stats" 
          competition="${this.competitionId}" season="${this.seasonId}" template="normal" show_crests="false" 
          show_images="false" show_ranks="true" show_player_rankings="true" show_team_rankings="true"  load="false"
          visible_stats="goals,shots,shots_on_target,passes,cards_yellow,cards_red" limit="3" 
          team_naming="full" player_naming="full" show_logo="false" title="${this.transHeadline}"
          show_title="true" breakpoints="400" sport="football"></opta-widget>

 
          `;
    }
}
