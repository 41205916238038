import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from '@angular/core';


import { SportConfigToken } from '@config/sport.config';

import { TranslateService } from '@ngx-translate/core';

import { OptaWidgetBase } from '../base.service';

declare const Opta: any;

@Component({
    selector: 'app-opta-team-ranking',
    templateUrl: './team-ranking.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptaWidgetTeamRankingComponent extends OptaWidgetBase implements AfterViewInit, OnDestroy {
    @Input()
    competitionId: string | number | null | undefined;

    @Input()
    seasonId: string | number | null | undefined;

      #headlineTrans = 'web.opta_competition_team_stats';
      private transHeadline = '';


      public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>,
          public translate: TranslateService) {
          super();
      }

      public ngAfterViewInit(): void {
          this.transHeadline = this.translate.instant(this.#headlineTrans);
          setTimeout(() => {
              const element = document.querySelector('#opta-team-ranking') as HTMLElement;

              if (element) {
                  element.innerHTML = this.getHtml();
                  const optaWidgetTags = document.querySelector('opta-widget[load="false"]');

                  if (optaWidgetTags) {
                      optaWidgetTags.removeAttribute('load');
                      Opta.start();
                  }
              }
          });
      }

      ngOnDestroy(): void {
          if (Opta.widgets.ref_widget_team_ranking) {
              Opta.widgets.ref_widget_team_ranking.destroy(true);
          }
      }

      getHtml(): string {
          return `
            <opta-widget widget="team_ranking"  widget_id="ref_widget_team_ranking" 
            competition="${this.competitionId}" season="${this.seasonId}" template="normal" navigation="tabs_more" 
            default_nav="1" show_crests="false" show_ranks="true"   load="false"
            visible_categories="fouls,passing_accuracy,goals,goal_conversion,shots,passes"
            limit="10" hide_zeroes="true" team_naming="full" show_logo="false"  title="${this.transHeadline}"
            show_title="true" breakpoints="400" sport="football"></opta-widget> 
          `;
      }
}
